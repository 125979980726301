import type {NextPage} from 'next'
import {LinkButton} from 'components/button'
import SEO from 'components/seo'

const NotFoundPage: NextPage = () => {
  return (
    <>
      <SEO title="404" />
      <div className="flex h-full flex-col items-center pt-32">
        <h1 className="mb-5 text-8xl text-yellow-400">404</h1>
        <p className="text-center text-lg">
          Trang bạn đang tìm kiếm không tồn tại
        </p>
        <LinkButton className="mt-10" href="/">
          Về trang chủ
        </LinkButton>
      </div>
    </>
  )
}

export default NotFoundPage
